import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentCategory, setCurrentSubcategory } from '../../redux/mainReducer';
import { AchievementSlider } from '../AchievementSlider/AchievementSlider';
import { Card } from '../Card/Card';
import './MainPage.scss';

export const MainPage = ({ categories }) => {

    const [isAboutOpen, setAboutOpen] = useState(false);

    const toggleAboutOpen = () => {
        setAboutOpen(!isAboutOpen);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentCategory(null));
        dispatch(setCurrentSubcategory(null));
    });

    return (
        <div className='main-page' data-testid='main-page-test'>
            <div className={`main-page__about-site ${isAboutOpen ? 'about-open' : ''}`}>
                <h2>О сайте</h2>
                <p>
                    Приветствуем на страницах нашего сайта учителей английского языка, родителей и, конечно, самих детей!
                </p>
                <br />
                <p>
                    Сайт предназначен для обучения младших школьников чтению на английском языке и основам грамматики, а также для коррекции ошибок при обучении английскому языку обучающихся более старшего возраста.
                </p>
                <br />
                <p>
                    Для обучения младших школьников технике чтения на английском языке предлагаем курс интенсивного обучения «ABC Street», где формирование навыков чтения осуществляется в дидактической игре посредством моделирования. Для закрепления нового материала разработаны занимательные тренировочные упражнения с заданиями, несущими мотивационную составляющую. При выполнении упражнений дети проводят само- и взаимоконтроль промежуточных результатов и самооценку конечных результатов своих действий.
                </p>
                <br />
                <p>
                    При обучении детей младшего школьного возраста основам грамматики английского языка предлагается учебное пособие «Grammar Smile». Для тренировки детей в употреблении грамматических навыков сконструированы различные виды опор с целью оптимизации учебно-воспитательного процесса. Тренировочные упражнения построены в эмоционально-образной форме, что открывает возможность формировать у детей эмоциональное отношение к предмету усвоения. Установка к действию через интерес несёт в себе фактор готовности производить учебную деятельность.
                </p>
                <br />
                <p>
                    Для всех участников учебно-воспитательного процесса подготовлены учебно-методические материалы:
                </p>
                <br />
                <ul>
                    <li>детям для индивидуальной работы - блокноты «My notepad»</li>
                    <li>учителям - методические рекомендации и сопроводительный материал в виде презентаций «Presentation»</li>
                </ul>
                <br />
                <p>
                    Для старшеклассников разработано учебное пособие «Построение всех видов вопросов  с  помощью опорных схем». Все операции по конструированию образцов-эталонов выполнены графически и показаны в их взаимной связи.
                </p>
                <br />
                <p>
                    Выражаем надежду, что предлагаемые учебные пособия окажутся  полезными Вам, уважаемые коллеги, и Вашим ученикам, родителям и студентам – будущим учителям английского языка.
                </p>
                <br />
            </div>
            <p onClick={toggleAboutOpen} className='main-page__about-site-toggle'>
                {isAboutOpen ? 'Свернуть' : 'Развернуть'}
            </p>
            <br />
            <h2>Учебные пособия</h2>
            <div className='main-page__cards'>
                {categories.map(category => {
                    return (
                        <Card category={category} key={category._id} />
                    );
                })}
            </div>
            <AchievementSlider />
        </div >
    );
};